import { server } from "../../../../../constants"
import { httpClient } from "../../../../../utils/HttpClient"

// Blog
const insertBlogFetch = async (param, body, accessToken) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_BLOG_URL, body, {
            headers: {
                'x-access-token': accessToken
            }
        })
        return result?.data ?? null
    } catch (err) { // status 404
        return null
    }
}

const getBlogFetch = async (param, body, accessToken) => { // Done
    try {
        const result = await httpClient.get(server.GET_BLOG_URL + `?title=${param.title}&isActive=${param.isActive}&page=${param.page}&size=${param.size}`, {
            headers: {
                'x-access-token': accessToken
            }
        })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getBlogByIdFetch = async (param, body, accessToken) => {
    try {
        const result = await httpClient.get(server.GET_BLOG_BY_ID_URL + `/${param.id}`, {
            headers: {
                'x-access-token': accessToken
            }
        })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const updateBlogFetch = async (param, body, accessToken) => {
    try {
        const result = await httpClient.put(server.UPDATE_BLOG_URL + `/${param.id}`, body, {
            headers: {
                'x-access-token': accessToken
            }
        })
        return result?.data ?? null
    } catch (err) { // status 404
        return null
    }
}

const deleteBlogByIdFetch = async (param, body, accessToken) => {
    try {
        const result = await httpClient.delete(server.DELETE_BLOG_BY_ID_URL + `/${param.id}`, {
            headers: {
                'x-access-token': accessToken
            }
        })
        return result?.data ?? null
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getBlogFetch,
    getBlogByIdFetch,

    // insert
    insertBlogFetch,

    // update
    updateBlogFetch,

    // delete
    deleteBlogByIdFetch
}
