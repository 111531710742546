
export const HTTP_BASKET_ORDER_FETCHING = "HTTP_BASKET_ORDER_FETCHING"
export const HTTP_BASKET_ORDER_SUCCESS = "HTTP_BASKET_ORDER_SUCCESS"
export const HTTP_BASKET_ORDER_FAILED = "HTTP_BASKET_ORDER_FAILED"

///////////////////////// Localization Begin /////////////////////////
export const NOT_CONNECT_NETWORK = "NOT_CONNECT_NETWORK"
export const NETWORK_CONNECTION_MESSAGE = "NETWORK_CONNECTION_MESSAGE"

export const serverUrl = "https://house-sale-house-rent.witte-bangkok.com"
// export const serverUrl = "http:///localhost:3001"

export const apiServerUrl = serverUrl // + "" "/api/v1"

export const imgDefaultUrl = `./assets/images/default/df-img.png`
export const videoDefaultUrl = `./assets/images/default/df-vdo.png`
export const imgDefaltCardCourse = `./assets/images/default/df-card-course-img.png`
export const img404notfound = `./assets/images/notfound/404notfound.jpg`

export const paginate = 10000 // limit 10 k
export const sortASC = "ASC"
export const sortDESC = "DESC"

export const YES = "YES"
export const NO = "NO"
export const OK = "ok"
export const NOK = "nok"

export const server = {
    // ------------------------ PROPERTIES -----------------------------------------------------
    GET_PROPERTIES_URL: `/api/properties`,
    GET_PROPERTIES_BY_ID_URL: `/api/properties`,
    GET_HOMEPAGE_PROPERTIES_URL:  `/api/homePage/properties`,
    GET_PROPERTY_DETAIL_PAGE_URL: `/api/propertyDetailPage`,

    INSERT_PROPERTIES_URL: `/api/properties`,

    UPDATE_PROPERTIES_URL: `/api/properties`,

    DELETE_PROPERTIES_BY_ID_URL: `/api/properties`,

    // ------------------------ PROPERTIES TYPE ------------------------------------------------
    GET_PROPERTY_TYPES_URL: `/api/propertyTypes`,
    GET_PROPERTY_TYPES_BY_ID_URL: `/api/propertyTypes`,

    INSERT_PROPERTY_TYPES_URL: `/api/propertyTypes`,

    UPDATE_PROPERTY_TYPES_URL: `/api/propertyTypes`,

    DELETE_PROPERTY_TYPES_BY_ID_URL: `/api/propertyTypes`,

    // ------------------------ PROPERTIES IMAGE -----------------------------------------------
    INSERT_PROPERTY_IMAGE_URL: `/api/property-images`,

    DELETE_PROPERTY_IMAGE_BY_ID_URL: `/api/property-images`,

    // ------------------------ BLOG -----------------------------------------------------------
    GET_BLOG_URL: `/api/blogs`,
    GET_BLOG_BY_ID_URL: `/api/blogs`,
    GET_HOMEPAGE_BLOG_URL: `/api/homePage/blogs`,
    GET_BLOG_DETAIL_PAGE_URL: `/api/blogDetailPage`,

    INSERT_BLOG_URL: `/api/blogs`,

    UPDATE_BLOG_URL: `/api/blogs`,

    DELETE_BLOG_BY_ID_URL: `/api/blogs`,

    // ------------------------ ERP ------------------------------------------------------------
    GET_ERP_SIGNIN_ID_URL: `/api/auth/signIn`,
    GET_ERP_SIGNUP_ID_URL: `/api/auth/signup`,
    GET_ERP_SIGNOUT_ID_URL: `/api/auth/signOut`,
    GET_ERP_LIST_URL: ``,
}
