/* eslint-disable array-callback-return */
/* eslint-disable no-useless-concat */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from "react"
import { useNavigate } from 'react-router-dom'
import { Row, Col, Button, Modal, AutoComplete, Pagination } from 'antd'
import { Icon } from '@iconify/react'
import { useDimensions } from "../../common/mamager/ScreenManager"

import "./css/index.css"
import { getHomePagePropertiesFetch } from "./API"
import LoadingPage from "../../common/components/loading/page"
import Card from "../../common/components/card"

export default function RealEstate(props) {
    const navigate = useNavigate()
    const { width } = useDimensions()

    const [realEstate, setRealEstate] = useState([])

    const [loading, setLoading] = useState(false)

    const searchRef = useRef('')

    const [options, setOptions] = useState([])

    const pageCurrentRef = useRef(1)
    const [pageSize, setPageSize] = useState(4)
    const [total, setTotal] = useState(0)
    const searchNameRef = useRef("")

    const handleChange = value => {
        // console.log("value : ", value)
        pageCurrentRef.current = value
        getPropertiesBySearch(searchNameRef.current)
    }

    const onSelect = async (value) => {
        setLoading(true)

        await getPropertiesBySearch(value)

        setLoading(false)
    }

    const handleSearch = async (value) => {
        // let obj = { title: value, paging: 10 }
        const result = []
        let data = []
        result?.map((val) => data.push(val.title))
        let searchResult = await data
            .map((data) => {
                return {
                    value: data,
                    label: (
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>{data}</span>
                        </div>
                    )
                }
            })

        pageCurrentRef.current = 1
        setOptions(value ? searchResult : [])
    }

    const getPropertiesBySearch = async (name) => {
        setLoading(true)

        let param = {
            name: name ?? "",
            propertyTypeId: props?.typeId,
            page: pageCurrentRef.current,
            size: pageSize
        }
        let result = await getHomePagePropertiesFetch(param, null, null)
        // console.log("getHomePagePropertiesFetch : ", result)
        setTotal(result?.totalItems)
        setRealEstate(result?.results)
        searchNameRef.current = name

        setLoading(false)
    }

    const getBaseApi = async () => {
        await getPropertiesBySearch(null)
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: props?.background,
            }} 
        >
            <div
                style={{
                    paddingTop: 40,
                    paddingBottom: 40,
                    paddingLeft: (width > 1200 ? 0 : 24),
                    paddingRight: (width > 1200 ? 0 : 24),
                    maxWidth: 1200,
                    width: "100%"
                }}
            >
                <Row>
                    <Col span={24}>
                        <div style={{ display: (width > 560 ? "flex" : "grid"), alignItems: "center", justifyContent: "space-between", borderRadius: 16 }}>
                            <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                <label style={{ paddingLeft: 0, fontSize: 20 }}>{props?.typeName}</label>
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center', width: (width > 560 ? 350 : "96%"), paddingTop: (width > 560 ? 0 : 6) }}>
                                <AutoComplete
                                    onChange={(e) => searchRef.current = e}
                                    dropdownMatchSelectWidth={252}
                                    style={{
                                        width: 285,
                                    }}
                                    value={searchRef.current}
                                    options={options}
                                    size="large"
                                    placeholder={"ค้นหาทำเล " + props.typeName}
                                    onSearch={handleSearch}
                                    onSelect={onSelect}
                                />

                                <div style={{ paddingLeft: 6 }}>
                                    <Button
                                        type="primary"
                                        style={{ width: 45 }}
                                        size="large"
                                        onClick={async (e) => {
                                            e.preventDefault()
                                            setLoading(true)

                                            await getPropertiesBySearch(searchRef.current)

                                            setLoading(false)
                                        }}
                                    >
                                        <Icon icon="bi:search" style={{ color: "white", width: 15, height: 15, marginTop: 5.5 }} />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div
                            style={{
                                paddingTop: 12,
                                paddingBottom: 0
                            }}
                        >
                            {!loading ?
                                <div style={{ borderRadius: 8 }}>
                                    <Row gutter={[0, 24]}>
                                        <Col span={24}>
                                            <Row gutter={[12, 12]}>
                                                {realEstate?.map((item, index) => {
                                                    return (
                                                        <Col xs={24} sm={12} md={8} lg={6}>
                                                            <Card
                                                                key={index}
                                                                index={index + 1}

                                                                id={item?.id}
                                                                name={item?.name}
                                                                numberOfFloor={item?.numberOfFloor}
                                                                numberOfBedroom={item?.numberOfBedroom}
                                                                numberOfBathroom={item?.numberOfBathroom}
                                                                numberOfUsableArea={item?.numberOfUsableArea}
                                                                numberOfParkCar={item?.numberOfParkCar}
                                                                price={item?.price}
                                                                isRent={item?.isRent}
                                                                isSale={item?.isSale}
                                                                image={item?.imageThumbnail}

                                                                heightBody={137}

                                                                pageType="real-estate"

                                                                isPaddingLeft={true}
                                                                isPaddingRight={true}
                                                                isPaddingTop={true}
                                                                isOnlyimage={true}
                                                            />
                                                        </Col>
                                                    )
                                                })
                                                }
                                            </Row>
                                        </Col>

                                        {total !== 0 ?
                                            <Col span={24} style={{ paddingTop: total > 12 ? 12 : 0, paddingBottom: 12, display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                                                <center>
                                                    <Pagination
                                                        defaultCurrent={pageCurrentRef.current}
                                                        defaultPageSize={pageSize}
                                                        onChange={handleChange}
                                                        total={total}
                                                    />
                                                </center>
                                            </Col>
                                            : []
                                        }

                                        {total === 0 ?
                                            <Col span={24}>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <Icon icon="mdi:file-find" style={{ fontSize: '1.5em', color: "#0099ff" }} />
                                                    <label style={{ paddingLeft: 8 }}>ไม่พบอสังหาฯ</label>
                                                </div>
                                            </Col>
                                            : []
                                        }
                                    </Row>
                                </div>
                                :
                                <Col span={24}>
                                    <LoadingPage loading={loading} />
                                </Col>
                            }

                            {/* <RealEstateList 
                            realEstate={realEstate} 
                            loading={loading} 

                            pageCurrent={pageCurrentRef.current}
                            pageSize={pageSize}
                            total={total}
                            searchName={searchNameRef.current}
                        /> */}
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}