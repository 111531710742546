/* eslint-disable array-callback-return */
/* eslint-disable no-useless-concat */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */

import { useNavigate } from "react-router"
import { useEffect, useRef, useState } from "react"
import { Col, Pagination, Row } from "antd"
import { useDimensions } from "../../common/mamager/ScreenManager"
import { getHomePageBlogsFetch } from "./API"
import Card from "../../common/components/card"
import LoadingPage from "../../common/components/loading/page"
import { Icon } from '@iconify/react'

export default function Blog(props) {
    const navigate = useNavigate()
    const { width } = useDimensions()

    const [blogs, setBlogs] = useState([])

    const [loading, setLoading] = useState(false)

    const pageCurrentRef = useRef(1)
    const [pageSize, setPageSize] = useState(4)
    const [total, setTotal] = useState(0)
    const searchNameRef = useRef("")

    const handleChange = value => {
        // console.log("value : ", value)
        pageCurrentRef.current = value
        getBlogsBySearch(searchNameRef.current)
    }

    const getBlogsBySearch = async (name) => {
        setLoading(true)

        let param = {
            page: pageCurrentRef.current,
            size: pageSize
        }

        let result = await getHomePageBlogsFetch(param, null, null)
        // console.log("getHomePageBlogsFetch : ", result?.results)
        setTotal(result?.totalItems)
        setBlogs(result?.results)
        searchNameRef.current = name

        setLoading(false)
    }

    const getBaseApi = async () => {
        await getBlogsBySearch("")
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: props?.background,
            }} >
            <div
                style={{
                    paddingTop: 68,
                    paddingBottom: 68,
                    paddingLeft: (width > 1200 ? 0 : 24),
                    paddingRight: (width > 1200 ? 0 : 24),
                    maxWidth: 1200,
                    width: "100%"
                }}
            >
                <Row>
                    <Col span={24}>
                        <div style={{ display: (width > 560 ? "flex" : "grid"), alignItems: "center", justifyContent: "space-between", borderRadius: 16, paddingRight: (width > 430 ? 12 : 0) }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                                <label style={{ paddingLeft: 12, fontSize: 26 }}>บทความ</label>
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div
                            style={{
                                paddingTop: 12,
                                paddingBottom: 0
                            }}
                        >
                            {!loading ?
                                <div style={{ borderRadius: 8 }}>
                                    <Row gutter={[0, 24]}>
                                        <Col span={24}>
                                            <Row gutter={[12, 12]}>
                                                {blogs?.map((item, index) => {
                                                    return (
                                                        <Col xs={24} sm={12} md={8} lg={8}>
                                                            <Card
                                                                key={index}
                                                                index={index + 1}

                                                                id={item?.id}
                                                                name={item?.title}
                                                                image={item?.imageThumbnail}

                                                                heightBody={50}

                                                                pageType="blog"

                                                                isPaddingLeft={true}
                                                                isPaddingRight={true}
                                                                isPaddingTop={true}
                                                                isOnlyimage={true}
                                                            />
                                                        </Col>
                                                    )
                                                })
                                                }
                                            </Row>
                                        </Col>

                                        {total !== 0 ?
                                            <Col span={24} style={{ paddingTop: total > 12 ? 12 : 0, paddingBottom: 12, display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                                                <center>
                                                    <Pagination
                                                        defaultCurrent={pageCurrentRef.current}
                                                        defaultPageSize={pageSize}
                                                        onChange={handleChange}
                                                        total={total}
                                                    />
                                                </center>
                                            </Col>
                                            : []
                                        }

                                        {total === 0 ?
                                            <Col span={24}>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <Icon icon="mdi:file-find" style={{ fontSize: '1.5em', color: "#0099ff" }} />
                                                    <label style={{ paddingLeft: 8 }}>ไม่พบบทความ</label>
                                                </div>
                                            </Col>
                                            : []
                                        }
                                    </Row>
                                </div>
                                :
                                <Col span={24}>
                                    <LoadingPage loading={loading} />
                                </Col>
                            }
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}