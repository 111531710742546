/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-duplicate-case */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useState, useEffect, useMemo } from 'react'
import {
    Button,
    Layout,
    Menu
} from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { useNavigate, useLocation } from 'react-router-dom'

import RealEstateManage from './manage/realEstate'
import BlogManage from './manage/blog'
import { getErpSignOutFetch } from './API'
import { Notification } from '../../common/components/notification'
import LoadingPage from '../../common/components/loading/page'

const { Sider } = Layout
// const { SubMenu } = Menu

export default function ERP(props) {
    const navigate = useNavigate()
    const location = useLocation()

    const [loading, setLoading] = useState(true)

    const [selectedMenuItem, setSelectedMenuItem] = useState("manage-product")

    const componentsSwitch = (type) => {
        switch (type) {
            // case "manage-admin":
            //     return <AdminManage />
            case "manage-product":
                return <RealEstateManage username={location.state.username} />
            case "manage-blog":
                return <BlogManage username={location.state.username} />
            default:
                return null
        }
    }

    const detectSignin = () => {
        setLoading(true)

        const accessToken = sessionStorage.getItem("accessToken")
        // console.log("X --- : ", accessToken)
        if (!accessToken) {
            navigate("/login-is-asset-home-back-office")
        } else {
            setLoading(false)
        }
    }

    useMemo(() => {
        detectSignin()
    }, [])

    useEffect(() => {
        setInterval(async () => {
            detectSignin()
        }, 1000)
    }, [])



    return (
        <>
            {!loading ?
                <div style={{ backgroundColor: '#ECECEC' }}>
                    <div style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <PageHeader
                            title={
                                <>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <div style={{ paddingLeft: 10, display: "grid", alignItems: "center", color: "white" }} >
                                            <label style={{ fontSize: 20, fontWeight: 500 }}>
                                                ขายบ้านเช่าบ้าน (Administrator)
                                            </label>
                                            <label style={{ color: "white", fontSize: 16, fontWeight: "initial" }}>
                                                <label style={{}}>ผู้ใช้งาน : {location.state ? location.state.username : "-"}</label>
                                            </label>
                                        </div>
                                    </div>
                                </>
                            }
                            extra={[
                                <Button
                                    type="text"
                                    onClick={async () => {
                                        // const accessToken = sessionStorage.getItem("accessToken")
                                        // const result = await getErpSignOutFetch(accessToken)
                                        // if (result.isSuccess) {
                                        navigate("/is-asset-home-back-office", { state: null })
                                        navigate("/login-is-asset-home-back-office")
                                        sessionStorage.removeItem('accessToken')
                                        // } else {
                                        //     Notification('error', 'ไม่สามารถออกจากระบบได้ กรุณาลองใหม่อีกครั้ง')
                                        // }
                                    }}
                                    style={{ marginTop: 16, color: "white" }}
                                >ออกจากระบบ</Button>
                            ]}
                            style={{ backgroundColor: 'black' }}
                        >
                        </PageHeader>

                        <Layout>
                            <Sider
                                width={200}
                                className="site-layout-background"
                            >
                                <Menu
                                    defaultSelectedKeys={['1']}
                                    defaultOpenKeys={['mamage-user']}
                                    mode="inline"
                                    theme="dark"
                                    selectedKeys={selectedMenuItem}
                                    onClick={(e) => {
                                        setSelectedMenuItem(e.key)
                                    }}
                                    style={{ height: '100%' }}
                                >
                                    <Menu.Item key="manage-product">
                                        <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการอสังหาฯ</label>
                                    </Menu.Item>

                                    <Menu.Item key="manage-blog">
                                        <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการบทความ</label>
                                    </Menu.Item>
                                </Menu>
                            </Sider>

                            <article style={{ backgroundColor: 'white', width: '100%', height: '100%' }}>
                                <div style={{ padding: 20 }}>
                                    {componentsSwitch(selectedMenuItem)}
                                </div>
                            </article>
                        </Layout>
                    </div>
                </div>
                :
                <LoadingPage />
            }
        </>
    )
}
