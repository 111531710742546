/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import Header from '../../common/components/header'
// import { getProductByIdFetch } from './API'
import { useDimensions } from '../../common/mamager/ScreenManager'
import parse from 'html-react-parser'
import Contact from '../home/components/contact'
import { useLocation } from 'react-router'
import { serverUrl } from '../../constants'
import Footer from '../../common/components/footer'
import { getBlogDetailPageFetch } from '../blog/API'
// import Video from '../../common/components/video'

export default function BlogDetail() {

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const blogId = params.get("blogId")

    const [loading, setLoading] = useState(false)

    const { width } = useDimensions()
    const [blog, setBlog] = useState()

    const getPartnerAll = async () => {
        setLoading(true)

        let param = {
            id: blogId
        }
        let result =  await getBlogDetailPageFetch(param, null, null)
        // console.log("getBlogDetailPageFetch : ", result[0], blogId)
        if (result?.length > 0) {
            setBlog(result[0])
        }
        // console.log("result : ", result)

        setLoading(false)
    }

    useEffect(() => {
        getPartnerAll()
    }, [])

    return (
        <>
            <Header />

            <div
                style={{
                    paddingTop: 70,
                    paddingLeft: width > 760 ? "15%" : 0,
                    paddingRight: width > 760 ? "15%" : 0,
                    backgroundColor: "#EEEEEE"
                }}
            >
                {!loading ?
                    <Row
                        gutter={[24, 24]}
                        style={{
                            backgroundColor: "white",
                            paddingTop: 24,
                            paddingBottom: 84,
                            paddingLeft: 24,
                            paddingRight: 24,
                        }}
                    >
                        <Col span={24}>
                            <label style={{ fontWeight: 600, fontSize: 20 }}>{blog?.title ?? "-"}</label>
                        </Col>

                        <Col span={24}>
                            <div style={{ paddingBottom: 6 }}>
                                <img
                                    // src={`${serverUrl}/${blog?.imageThumbnail}`}
                                    src={`${blog?.imageThumbnail}`}
                                    style={{ objectFit: "cover", width: "100%", borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
                                />
                            </div>
                        </Col>

                        <Col span={24}>
                            <div style={{ paddingBottom: 6 }}>
                                <label style={{ fontWeight: 600 }}>รายละเอียด</label>
                            </div>
                            <div style={{ paddingBottom: 12 }}>
                                <label>{blog?.description ? parse(blog?.description ?? "-") : "-"}</label>
                            </div>
                        </Col>
                    </Row>
                    : []
                }
            </div>

            <Footer />
        </>
    )
}
