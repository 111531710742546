/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    Popconfirm,
    Select,
    Upload,
    // DatePicker   
} from 'antd'
import { FaCog } from "react-icons/fa"
import moment from 'moment'
import { Icon } from '@iconify/react'
import { Notification } from '../../../../common/components/notification'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { apiServerUrl } from '../../../../constants'
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import { deleteBlogByIdFetch, getBlogFetch, insertBlogFetch, updateBlogFetch } from './API/blogApi'

const { TextArea } = Input;
const { Option } = Select
// const { RangePicker } = DatePicker

const columns = [
    {
        title: 'No.',
        dataIndex: 'index',
        width: "10%",
    },
    {
        title: 'รายการบทความ',
        dataIndex: 'title',
        width: "35%",
    },
    {
        title: 'สถานะ',
        width: "15%",
        render: (text, record) => {
            return (
                <>
                    {record.isActive ?
                        <label>เปิดใช้งาน</label>
                        :
                        <label>ปิดใช้งาน</label>
                    }
                </>
            )
        }
    },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        width: "10%",
    },
    {
        title: 'เเก้ไขล่าสุด',
        dataIndex: 'updatedAt',
        width: "10%",
    },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        align: "center",
        width: "20%",
    },
]

const formatDate = "DD/MM/YYYY"

export default function BlogManage() {

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)

    const [formBlog] = Form.useForm()
    const [formSearch] = Form.useForm()

    const accessToken = sessionStorage.getItem("accessToken")

    const [imageBlogURL, setImageBlogURL] = useState({
        loading: false,
        imageUrl: null
    })

    const [detail, setDetail] = useState()
    const detailRef = useRef()

    const pageCurrentRef = useRef(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)
    const searchNameRef = useRef("")

    const [modalBlog, setModalBlog] = useState({
        isShow: false,
        title: null
    })

    const optionBlogImage = {
        name: 'file',
        action: `${apiServerUrl}/api/upload?type=property`,
        data: {
            name: "admin",
        },
        headers: {
            'x-access-token': accessToken
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                let result = info.file.response
                if (result?.isSuccess) {
                    // console.log("A --- : ", result.formData.fileUrl)
                    setImageBlogURL({
                        imageUrl: result.formData.fileUrl,
                        loading: false
                    })
                }
            } else {
                setImageBlogURL({
                    imageUrl: imageBlogURL.imageUrl,
                    loading: true
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const getBlogAll = async (title) => {
        setLoading(true)

        let param = {
            title,
            isActive: "",
            page: pageCurrentRef.current,
            size: pageSize
        }
        const result = await getBlogFetch(param, null, accessToken)
        // console.log("getBlogFetch : ", result?.results)
        setTotal(result?.totalItems)
        let tempList = []
        result?.results?.map((val, index) => {
            tempList.push({
                index: (pageCurrentRef.current * pageSize-10) + (index + 1),
                title: val.title,
                isActive: val.isActive,
                createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                updatedAt: val.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                operator:
                    <>
                        <Button
                            style={{
                                width: 35,
                                backgroundColor: "orange",
                                border: "1px solid orange",
                                color: 'white',
                                borderRadius: 50
                            }}
                            onClick={async () => {
                                formBlog.setFieldsValue({
                                    id: val.id,
                                    isActive: val.isActive,
                                    title: val.title,
                                })

                                setImageBlogURL({
                                    loading: false,
                                    imageUrl: val.imageThumbnail
                                })

                                setDetail(val.description ?? "")
                                detailRef.current = val.description ?? ""

                                setModalBlog({ isShow: true, title: "edit" })
                            }}
                        >
                            <div style={{ marginTop: 0, marginLeft: -8 }}>
                                <Icon icon="typcn:edit" style={{ color: "white", width: 20, height: 20 }} />
                            </div>
                        </Button>{"  "}

                        <Popconfirm
                            title="คุณยืนยันลบหรือไม่ ?"
                            okText={<span style={{ width: 50 }}>ใช่</span>}
                            onConfirm={async () => {
                                await handleBlogDelete(val.id)

                                // reload
                                await getBlogAll("")
                            }}
                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                        >
                            <Button
                                danger
                                type="primary"
                                style={{
                                    width: 35,
                                    borderRadius: 50
                                }}
                            >
                                <div style={{ marginTop: 0, marginLeft: -8 }}>
                                    <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 20, height: 20 }} />
                                </div>
                            </Button>
                        </Popconfirm>
                    </>
            })
        })
        setList(tempList)
        searchNameRef.current = title

        setLoading(false)
    }

    const onBlogFinish = async (values) => {
        let param = {
            id: values.id ? values.id : ""
        }

        let body = {
            "title": values.title ? values.title : "",
            "imageThumbnail": imageBlogURL.imageUrl ? imageBlogURL.imageUrl : "",
            "imageDetail": "https://imageDetail",
            "description": detailRef.current ? detailRef.current : "",
            "isActive": values.isActive ? 1 : 0,
        }
        // console.log("body : ", body)

        if (modalBlog.title === "add") {
            const result = await insertBlogFetch(null, body, accessToken)
            if (result.isSuccess) {
                Notification('success', 'สร้างสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง')
            }
        } else if (modalBlog.title === "edit") {
            const result = await updateBlogFetch(param, body, accessToken)
            if (result.isSuccess) {
                Notification('success', 'เเก้ไขสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
            }
        }

        // reload
        getBlogAll("")

        // set default
        setFormBlogDefault()
    }

    const onSearchFinish = async (values) => {
        let title = values?.title ? values.title : ""
        await getBlogAll(title)
    }

    const handleBlogDelete = async (id) => {
        let param = {
            id
        }
        const result = await deleteBlogByIdFetch(param, null, accessToken)
        if (result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const onPagine = (n) => {
        pageCurrentRef.current = n.current
        getBlogAll(searchNameRef.current)
    }

    const setFormBlogDefault = () => {
        formBlog.setFieldsValue({
            productId: undefined,
            title: undefined,
            isActive: undefined
        })

        setImageBlogURL({
            loading: false,
            imageUrl: null
        })

        setDetail("")
        detailRef.current = ""

        setModalBlog({
            isShow: false,
            title: null
        })
    }

    const getBaseApi = async () => {
        getBlogAll("")
    }
    
    useEffect(() => {
        // console.log("accessToken : ", accessToken)
        getBaseApi()
    }, [])

    return (
        <Row>
            <Col span={12}>
                <label>จัดการบทความ</label>
            </Col>

            <Col span={12} style={{ paddingBottom: 20 }}>
                <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                // label="ชื่ออสังหา"
                                name="title"
                                style={{ width: '100%' }}
                            >
                                <Input placeholder="ค้นหาชื่ออสังหา" />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10, marginTop: -24 }}>
                            <Button
                                style={{ float: 'right', width: 70 }}
                                type="primary"
                                onClick={() => formSearch.submit()}
                            >
                                ค้นหา
                            </Button>
                        </div>
                    </div>
                </Form>
            </Col>

            <Col span={24} style={{ paddingBottom: 20 }}>
                <Button
                    type="primary"
                    style={{ float: 'right' }}
                    onClick={() => {
                        formBlog.resetFields()

                        setModalBlog({
                            isShow: true,
                            title: "add"
                        })
                    }}
                >เพิ่มรายการ</Button>
            </Col>

            <Col span={24}>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        current: pageCurrentRef.current,
                        pageSize: pageSize,
                        total: total
                    }}
                    onChange={(n) => onPagine(n)}
                >
                </Table>
            </Col>

            <Modal
                title={<strong><label className="topic-color-bold">{modalBlog.title === "add" ? "เพิ่มข้อมูล" : "เเก้ไขข้อมูล"}</label></strong>}
                visible={modalBlog.isShow}
                zIndex={999}
                onCancel={() => {

                    // default
                    setFormBlogDefault()
                }}
                width={700}
                onOk={() => {
                    formBlog.submit()
                }}
                okText={<label style={{ width: 50, cursor: 'pointer' }}>บันทึก</label>}
                cancelText={<label style={{ width: 50, cursor: 'pointer' }}>ยกเลิก</label>}
            >
                <Form
                    layout="vertical"
                    form={formBlog}
                    onFinish={onBlogFinish}
                >
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <Form.Item name="id" style={{ display: "none" }}>
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="title"
                                label="ชื่อบทความ"
                                rules={[{ required: true, message: 'กรุณากรอกชื่อบทความ' }]}
                            >
                                <TextArea
                                    autoSize={{ minRows: 1, maxRows: 2 }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name="description_detail"
                                label="รายละเอียดบทความ"
                            >
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={{
                                        toolbar: {
                                            items: [
                                                'heading', '|',
                                                'fontfamily', 'fontsize', '|',
                                                'bold', 'italic', 'underline', '|',
                                                'alignment', '|',
                                                'fontColor', 'fontBackgroundColor', '|',
                                                'bulletedList', 'numberedList', 'todoList', '|',
                                                'code', 'codeBlock', '|',
                                                'undo', 'redo'
                                            ],
                                            removeButtons: 'Subscript,Superscript',
                                            height: 350
                                        }
                                    }}
                                    data={detail}
                                    onBlur={(event, editor) => {
                                        const data = editor.getData()
                                        detailRef.current = data
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12} xl={12}>
                            <div style={{ display: "grid" }}>
                                <label style={{ paddingBottom: 6 }}>ภาพปกบทความ</label>
                                {imageBlogURL?.imageUrl ?
                                    <img
                                        style={{ borderRadius: 8, maxWidth: 300, border: "1px solid #EEEEEE" }}
                                        src={`${imageBlogURL.imageUrl}`}
                                    />
                                    :
                                    <img
                                        style={{ width: "100%", borderRadius: 8, border: "1px solid #C4C4C4" }}
                                        src={`./assets/images/default/df-img.png`}
                                    />
                                }
                                <div style={{ paddingTop: 12 }}>
                                    <Upload
                                        {...optionBlogImage}
                                        accept='image/jpeg, image/png, image/jfif'
                                        style={{ width: "100%" }}
                                        maxCount={1}
                                        showUploadList={false}
                                    >
                                        <Button
                                            type="default"
                                            style={{ width: "100%" }}
                                            icon={imageBlogURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                        >อัพโหลดรูปภาพ</Button>
                                    </Upload>
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} md={12} xl={12}>
                            <Form.Item
                                name="isActive"
                                label="เเสดงบทความนี้หรือไม่ ?"
                                rules={[{ required: true, message: 'กรุณาเลือกสถานะการเเสดง' }]}
                            >
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    allowClear
                                >
                                    <Option key={0} value={true}>เปิด</Option>
                                    <Option key={1} value={false}>ปิด</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Row>
    )
}
