/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Col, Row } from 'antd'
import { useEffect, useState } from 'react'
import Header from '../../common/components/header'
import { useDimensions } from '../../common/mamager/ScreenManager'
import parse from 'html-react-parser'
import { useLocation } from 'react-router'
import Footer from '../../common/components/footer'
import Gallery from '../../common/components/gallery'
import { FaPhoneAlt } from "react-icons/fa"
import { Icon } from '@iconify/react'
import {
    getPropertyDetailPageFetch
} from '../realEstate/API'

export default function RealEstateDetail() {

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const realEstateId = params.get("realEstateId")

    const [loading, setLoading] = useState(false)

    const { width } = useDimensions()
    const [realEstate, setRealEstate] = useState()
    const [realEstateGallery, setRealEstateGallery] = useState([])

    const getPartnerAll = async () => {
        setLoading(true)

        let param = {
            id: Number(realEstateId)
        }
        let result = await getPropertyDetailPageFetch(param, null, null)
        // console.log("getPropertyDetailPageFetch : ", result[0])
        if (result?.length > 0) {
            setRealEstate(result[0])
            setRealEstateGallery(result[0]?.propertyImages)
        }

        setLoading(false)
    }

    const getBaseApi = async () => {
        await getPartnerAll()
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <>
            <Header />

            <div
                style={{
                    backgroundColor: "#EEEEEE",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <div
                    style={{
                        paddingTop: 70,
                        paddingLeft: (width > 1200 ? 0 : 24),
                        paddingRight: (width > 1200 ? 0 : 24),
                        maxWidth: 1200,
                        width: "100%"
                    }}
                >
                    {!loading ?
                        <>
                            <Row
                                gutter={[0, 24]}
                                style={{
                                    backgroundColor: "white",
                                    paddingTop: 24,
                                    paddingBottom: 84,
                                    paddingLeft: 24,
                                    paddingRight: 24,
                                }}
                            >
                                <Col span={24}>
                                    <label style={{ fontWeight: 600, fontSize: 20 }}>{realEstate?.name ?? "-"}</label>
                                </Col>

                                {/* <Col xs={24} md={12} xl={8}>
                                <div style={{ paddingBottom: 6 }}>
                                    <img
                                        src={`${serverUrl}/${realEstate?.imageURL}`}
                                        style={{ objectFit: "cover", width: "100%", height: 200, borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
                                    />
                                </div>
                            </Col> */}

                                <Col span={24}>
                                    <div style={{ paddingBottom: 6 }}>
                                        <label style={{ fontWeight: 600 }}>รายละเอียด</label>
                                    </div>
                                    <div style={{ paddingBottom: 12 }}>
                                        <label>{realEstate?.descriptionDetail ? parse(realEstate?.descriptionDetail ?? "-") : "-"}</label>
                                    </div>
                                </Col>
                            </Row>

                            <div
                                style={{
                                    padding: 24,
                                    backgroundColor: "#EEEEEE",
                                    display: (width > 430 ? "flex" : "grid"),
                                    alignItems: "center",
                                    justifyContent: "flex-start"
                                }}
                            >
                                <label style={{ fontWeight: 600 }}>ติดต่อเราเลย</label>

                                <div style={{ paddingLeft: (width > 430 ? 24 : 0), paddingTop: (width > 430 ? 0 : 12) }}>
                                    <a href="tel:+66614545490" style={{ color: "inherit", textDecoration: "none" }}>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                            <div style={{ width: 35, height: 35, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#9D00FF", borderRadius: "8px 0px 0px 8px" }}>
                                                <FaPhoneAlt style={{ color: "white" }} />
                                            </div>
                                            <div style={{ width: 130, height: 35, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "white", borderRadius: "0px 8px 8px 0px" }}>
                                                <label style={{ cursor: "pointer" }}>061-454-5490</label>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div style={{ paddingLeft: (width > 430 ? 24 : 0), paddingTop: (width > 430 ? 0 : 12) }}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                        <div
                                            style={{ cursor: "pointer", width: 30, height: 30, padding: 2, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#9D00FF", borderRadius: 20 }}
                                            onClick={() => {
                                                window.open("https://www.facebook.com/profile.php?id=61551243572018&ref=embed_page")
                                            }}
                                        >
                                            <Icon icon="jam:facebook" style={{ fontSize: '1.3em', color: "white" }} />
                                        </div>

                                        <div style={{ paddingLeft: 12 }}>
                                            <div
                                                style={{ cursor: "pointer", width: 30, height: 30, padding: 2, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#9D00FF", borderRadius: 20 }}
                                                onClick={() => {
                                                    window.open("https://line.me/R/ti/p/@377wudef?oat_content=url")
                                                }}
                                            >
                                                <Icon icon="bi:line" style={{ fontSize: '1.3em', color: "white" }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style={{  }}>
                                <Gallery
                                    typeId={1}
                                    loading={loading}
                                    data={realEstateGallery}
                                    typeName={"รูปภาพ"}
                                    isOpenPaddingLeft={false}
                                    isOpenPaddingRight={false}
                                    backgroundColor={"white"}
                                    isOpenBaseApi={false}
                                />
                            </div>

                        </>
                        : []
                    }
                </div>
            </div>

            <Footer />
        </>
    )
}
