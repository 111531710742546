/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    Popconfirm,
    Select,
    Upload,
    InputNumber,
    Radio,
    // DatePicker 
} from 'antd'
import { FaCog } from "react-icons/fa"
import moment from 'moment'
import { Icon } from '@iconify/react'
import { Notification } from '../../../../common/components/notification'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { apiServerUrl } from '../../../../constants'
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import { getPropertyTypesFetch } from './API/propertyTypeApi'
import { deletePropertiesByIdFetch, getPropertiesFetch, insertPropertiesFetch, updatePropertiesFetch } from './API/propertyApi'
import { deletePropertyImageByIdFetch, insertPropertyImageFetch } from './API/propertyImage'

const { TextArea } = Input;
const { Option } = Select
// const { RangePicker } = DatePicker

const columns = [
    {
        title: 'No.',
        dataIndex: 'index',
        width: "10%",
    },
    {
        title: 'รายการอสังหาฯ',
        dataIndex: 'name',
        width: "30%",
    },
    {
        title: 'ประเภทอสังหาฯ',
        dataIndex: 'propertyType',
        width: "15%",
    },
    {
        title: 'สถานะ',
        width: "15%",
        render: (text, record) => {
            return (
                <>
                    {record.isActive ?
                        <label>เบิดขาย</label>
                        :
                        <label>ปิดขาย</label>
                    }
                </>
            )
        }
    },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        width: "10%",
    },
    {
        title: 'เเก้ไขล่าสุด',
        dataIndex: 'updatedAt',
        width: "10%",
    },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        align: "center",
        width: "20%",
    },
]

const formatDate = "DD/MM/YYYY"

export default function RealEstateManage(props) {
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingPropertyGallery, setLoadingPropertyGallery] = useState(false)

    const [formProperty] = Form.useForm()
    // const [formPropertyGallery] = Form.useForm()
    const [formSearch] = Form.useForm()

    const accessToken = sessionStorage.getItem("accessToken")

    const [propertyId, setPropertyId] = useState()

    const [propertyTypes, setPropertyTypes] = useState([])
    const [propertyGallery, setPropertyGallery] = useState([])

    const [imagePropertyURL, setImagePropertyURL] = useState({
        loading: false,
        imageUrl: null
    })

    const isActivePropertyGalleryRef = useRef(false)

    const [imagePropertyGalleryURL, setImagePropertyGalleryURL] = useState({
        loading: false,
        imageUrl: null
    })

    // const [videoProductDetailURL, setVideoProductDetailURL] = useState({
    //     loading: false,
    //     videoUrl: null
    // })

    const [detail, setDetail] = useState()
    const detailRef = useRef()

    // const [promotion, setPromotion] = useState()
    // const promotionRef = useRef()

    const pageCurrentRef = useRef(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)
    const searchNameRef = useRef("")

    const [modalProperty, setModalProperty] = useState({
        isShow: false,
        title: null
    })

    // const [modalPropertyGallery, setModalPropertyGallery] = useState({
    //     isShow: false,
    //     title: null
    // })

    const optionPropertyImage = {
        name: 'file',
        action: `${apiServerUrl}/api/upload?type=property`,
        data: {
            name: "admin"
        },
        headers: {
            'x-access-token': accessToken
        },
        onChange(info) {

            // const newFileName = generateNewFileName(info.file.name)
            // optionPropertyImage.data.name = newFileName

            if (info.file.status !== 'uploading') {
                let result = info.file.response
                if (result?.isSuccess) {
                    // console.log("A1 --- : ", result.formData.fileUrl)
                    setImagePropertyURL({
                        imageUrl: result.formData.fileUrl,
                        loading: false
                    })
                }
            } else {
                setImagePropertyURL({
                    imageUrl: imagePropertyURL.imageUrl,
                    loading: true
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const optionPropertyGalleryImage = {
        name: 'file',
        action: `${apiServerUrl}/api/upload?type=propertyGallery`,
        data: {
            name: "admin"
        },
        headers: {
            'x-access-token': accessToken
        },
        async onChange(info) {

            // const newFileName = generateNewFileName(info.file.name)
            // optionPropertyGalleryImage.data.name = newFileName

            let result = info.file.response
            if (info.file.status !== 'uploading') {
                if (result?.isSuccess) {
                    // console.log("A2 --- : ", result.formData.fileUrl)
                    setImagePropertyGalleryURL({
                        imageUrl: result.formData.fileUrl,
                        loading: false
                    })
                }
            } else {
                setImagePropertyGalleryURL({
                    imageUrl: imagePropertyGalleryURL.imageUrl,
                    loading: true
                })
            }

            if (info.file.status === 'done') {
                setLoadingPropertyGallery(true)

                Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ")

                let body = {
                    propertyId,
                    image: result.formData.fileUrl
                }
                const resultPropertyImage = await insertPropertyImageFetch(null, body, accessToken)
                isActivePropertyGalleryRef.current = false

                let obj = {
                    propertyId,
                    image: result.formData.fileUrl,
                    id: resultPropertyImage?.id
                }
                let tmpPropertyGallery = propertyGallery
                tmpPropertyGallery?.push(obj)
                // console.log("tmpPropertyGallery : ", tmpPropertyGallery)
                setPropertyGallery(tmpPropertyGallery)

                setLoadingPropertyGallery(false)
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    // function generateNewFileName(oldFileName) {
    //     // สร้างชื่อไฟล์ใหม่ที่นี่ เช่น เพิ่ม timestamp เป็นส่วนหนึ่งของชื่อไฟล์
    //     const timestamp = Date.now()
    //     const extension = oldFileName.split('.').pop()
    //     const newFileName = `${props.username}-${timestamp}.${extension}`
    //     return newFileName
    // }

    // const optionProductDetailVideo = {
    //     name: 'file',
    //     action: `${apiServerUrl}/upload/file`,
    //     data: {
    //         name: "admin",
    //         path: "upload_file/video/product"
    //     },
    //     headers: {
    //         authorization: 'authorization-text',
    //     },
    //     onChange(info) {
    //         if (info.file.status !== 'uploading') {
    //             if (info.fileList.length > 0) {
    //                 setVideoProductDetailURL({
    //                     videoUrl: info.file.response.filePath,
    //                     loading: false,
    //                 })
    //             }
    //         } else {
    //             setVideoProductDetailURL({
    //                 loading: true,
    //                 videoUrl: videoProductDetailURL.videoUrl
    //             })
    //         }

    //         if (info.file.status === 'done') {
    //             Notification("success", "เเจ้งเตือน!", "อัพโหลดวิดีโอสำเร็จ")
    //         } else if (info.file.status === 'error') {
    //             Notification("error", "เเจ้งเตือน!", "อัพโหลดวิดีโอไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
    //         }
    //     },
    //     progress: {
    //         strokeColor: {
    //             '0%': '#FF7F00',
    //             '100%': '#FF7F00',
    //         },
    //         strokeWidth: 3,
    //         width: '10%',
    //         format: percent => `${parseFloat(percent.toFixed(0))}%`,
    //     }
    // }

    const getPropertiesAll = async (name) => {
        setLoading(true)

        let param = {
            propertyTypeId: "",
            name,
            isActive: "",
            page: pageCurrentRef.current,
            size: pageSize
        }
        const result = await getPropertiesFetch(param, null, accessToken)
        // console.log("getPropertiesFetch : ", result)
        setTotal(result?.totalItems)
        let tempList = []
        result?.results?.map((val, index) => {
            tempList.push({
                index: (pageCurrentRef.current * pageSize - 10) + (index + 1),
                name: val.name, // ชื่ออสังหา
                propertyType: val?.propertyType?.name, // ประเภทอสังหา
                isActive: val.isActive, // สถานะการใช้งาน
                createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                updatedAt: val.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                operator:
                    <>
                        <Button
                            style={{
                                width: 35,
                                backgroundColor: "orange",
                                border: "1px solid orange",
                                color: 'white',
                                borderRadius: 50
                            }}
                            onClick={async () => {
                                formProperty.setFieldsValue({
                                    id: val.id,
                                    name: val.name, // ชื่ออสังหา
                                    propertyTypeId: val.propertyTypeId, // ประเภทอสังหา
                                    isActive: val.isActive, // สถานะการใช้งาน
                                    numberOfFloor: val.numberOfFloor, // ชั้น, จำนวนชั้น
                                    numberOfBedroom: val.numberOfBedroom, // จำนวนห้องนอน
                                    numberOfBathroom: val.numberOfBathroom, // จำนวนห้องน้ำ
                                    numberOfUsableArea: val.numberOfUsableArea, // พื้นที่ใช้งาน
                                    numberOfParkCar: val.numberOfParkCar, // จำนวนที่จอดรถ
                                    price: val.price, // ราคา
                                    isRent: val.isRent, // สถานะเช่า
                                    isSale: val.isSale, // สถานะขาย
                                })

                                setImagePropertyURL({
                                    loading: false,
                                    imageUrl: val.imageThumbnail
                                })

                                setPropertyId(val.id)
                                // console.log("propertyId : ", val.id)

                                setPropertyGallery(val.propertyImages)

                                // setVideoProductDetailURL({
                                //     loading: false,
                                //     videoUrl: val.videoURL
                                // })

                                setDetail(val.descriptionDetail ?? "")
                                detailRef.current = val.descriptionDetail ?? ""

                                setModalProperty({ isShow: true, title: "edit" })
                            }}
                        >
                            <div style={{ marginTop: 0, marginLeft: -8 }}>
                                <Icon icon="typcn:edit" style={{ color: "white", width: 20, height: 20 }} />
                            </div>
                        </Button>{"  "}

                        <Popconfirm
                            title="คุณยืนยันลบหรือไม่ ?"
                            okText={<span style={{ width: 50 }}>ใช่</span>}
                            onConfirm={async () => {
                                await handlePropertiesDelete(val.id)

                                // reload
                                await getPropertiesAll("")
                            }}
                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                        >
                            <Button
                                danger
                                type="primary"
                                style={{
                                    width: 35,
                                    borderRadius: 50
                                }}
                            >
                                <div style={{ marginTop: 0, marginLeft: -8 }}>
                                    <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 20, height: 20 }} />
                                </div>
                            </Button>
                        </Popconfirm>
                    </>
            })
        })
        setList(tempList)
        searchNameRef.current = name

        setLoading(false)
    }

    const onPropertyFinish = async (values) => {
        let param = {
            id: values.id ? values.id : ""
        }

        let body = {
            "propertyTypeId": values.propertyTypeId ? values.propertyTypeId : "",
            "imageThumbnail": imagePropertyURL.imageUrl ? imagePropertyURL.imageUrl : "",
            "slugName": "slugName",
            "name": values.name ? values.name : "",
            "locationName": "locationName",
            "numberOfFloor": values.numberOfFloor,
            "numberOfBedroom": values.numberOfBedroom,
            "numberOfBathroom": values.numberOfBathroom,
            "numberOfUsableArea": values.numberOfUsableArea,
            "numberOfParkCar": values.numberOfParkCar,
            "price": values.price ? values.price : 0,
            "isRent": values.isRent ? 1 : 0,
            "isSale": values.isSale ? 1 : 0,
            "priceType": "priceType",
            "titleDetail": "titleDetail",
            "descriptionDetail": detailRef.current ? detailRef.current : "",
            "mobilePhone": "mobilePhone",
            "email": "email@email.com",
            "facebook": "https://facebook.com",
            "isActive": values.isActive ? 1 : 0,
            "line": "line"
        }
        // console.log("body : ", body)

        if (modalProperty.title === "add") {
            const result = await insertPropertiesFetch(null, body, accessToken)
            if (result.isSuccess) {
                Notification('success', 'สร้างสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง')
            }
        } else if (modalProperty.title === "edit") {
            const result = await updatePropertiesFetch(param, body, accessToken)
            if (result.isSuccess) {
                Notification('success', 'เเก้ไขสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
            }
        }

        // reload
        getPropertiesAll("")

        // set default
        setFormPropertyDefault()
    }

    // const onPropertyGalleryFinish = async (values) => {

    // }

    const onSearchFinish = async (values) => {
        let title = values?.title ? values.title : ""
        await getPropertiesAll(title)
    }

    const handlePropertiesDelete = async (id) => {
        let param = {
            id
        }
        const result = await deletePropertiesByIdFetch(param, null, accessToken)
        if (result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const handlePropertiesGalleryDelete = async (id) => {
        let param = {
            id
        }
        // console.log("param : ", param)
        const result = await deletePropertyImageByIdFetch(param, null, accessToken)
        if (result.isSuccess) {

            let tmpPropertyGallery = propertyGallery?.filter(fill => fill.id !== id)
            // console.log("tmpPropertyGallery : ", tmpPropertyGallery)
            setPropertyGallery(tmpPropertyGallery)

            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const onPagine = (n) => {
        pageCurrentRef.current = n.current
        getPropertiesAll(searchNameRef.current)
    }

    const setFormPropertyDefault = () => {
        formProperty.setFieldsValue({
            productId: undefined,
            title: undefined,
            isActive: undefined
        })

        setImagePropertyURL({
            loading: false,
            imageUrl: null
        })

        setImagePropertyGalleryURL({
            loading: false,
            imageUrl: null
        })

        // setVideoProductDetailURL({
        //     loading: false,
        //     videoUrl: null
        // })

        setDetail("")
        detailRef.current = ""

        setModalProperty({
            isShow: false,
            title: null
        })
    }

    // const setFormPropertyGalleryDefault = () => {
    //     // formPropertyGallery.setFieldsValue({
    //     //     propertyId: undefined,
    //     // })

    //     setModalPropertyGallery({
    //         isShow: false,
    //         title: null
    //     })
    // }

    const getPropertyTypesAll = async () => {
        let param = {
            name: "",
            isActive: "",
            page: 1,
            size: 6
        }
        let result = await getPropertyTypesFetch(param, null, accessToken)
        // console.log("getPropertyTypesFetch : ", result?.results)
        setPropertyTypes(result?.results)
    }

    const getBaseApi = async () => {
        getPropertyTypesAll()
        getPropertiesAll("")
    }

    // console.log("propertyGallery : ", propertyGallery)

    useEffect(() => {
        // console.log("accessToken : ", accessToken)
        getBaseApi()
    }, [])

    useEffect(() => {

    }, [propertyGallery])

    return (
        <Row>
            <Col span={12}>
                <label>จัดการอสังหาฯ</label>
            </Col>

            <Col span={12} style={{ paddingBottom: 20 }}>
                <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                // label="ชื่ออสังหา"
                                name="title"
                                style={{ width: '100%' }}
                            >
                                <Input placeholder="ค้นหาชื่ออสังหา" />
                            </Form.Item>
                        </div>

                        {/* <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="ช่วงวันที่สร้าง" name="dateRange"
                                style={{ width: '100%' }}
                            >
                                <RangePicker />
                            </Form.Item>
                        </div> */}

                        <div style={{ paddingLeft: 10, marginTop: -24 }}>
                            <Button
                                style={{ float: 'right', width: 70 }}
                                type="primary"
                                onClick={() => formSearch.submit()}
                            >
                                ค้นหา
                            </Button>
                        </div>
                    </div>
                </Form>
            </Col>

            <Col span={24} style={{ paddingBottom: 20 }}>
                <Button
                    type="primary"
                    style={{ float: 'right' }}
                    onClick={() => {
                        formProperty.resetFields()

                        setPropertyGallery([])

                        setModalProperty({
                            isShow: true,
                            title: "add"
                        })
                    }}
                >เพิ่มรายการ</Button>
            </Col>

            <Col span={24}>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        current: pageCurrentRef.current,
                        pageSize: pageSize,
                        total: total
                    }}
                    onChange={(n) => onPagine(n)}
                >
                </Table>
            </Col>

            <Modal
                title={<strong><label className="topic-color-bold">{modalProperty.title === "add" ? "เพิ่มข้อมูล" : "เเก้ไขข้อมูล"}</label></strong>}
                visible={modalProperty.isShow}
                zIndex={999}
                onCancel={() => {

                    // default
                    setFormPropertyDefault()
                }}
                width={"90%"}
                onOk={() => {
                    formProperty.submit()
                }}
                okText={<label style={{ width: 50, cursor: 'pointer' }}>บันทึก</label>}
                cancelText={<label style={{ width: 50, cursor: 'pointer' }}>ยกเลิก</label>}
            >
                <Form
                    layout="vertical"
                    form={formProperty}
                    onFinish={onPropertyFinish}
                >
                    <Row gutter={[24, 0]}>
                        <Col xs={24} md={12} xl={12}>
                            <Row gutter={[24, 0]}>
                                <Col span={24}>
                                    <Form.Item name="id" style={{ display: "none" }}>
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        name="name"
                                        label="ชื่ออสังหาริมทรัพย์"
                                        rules={[{ required: true, message: 'กรุณากรอกชื่ออสังหาริมทรัพย์' }]}
                                    >
                                        <TextArea
                                            autoSize={{ minRows: 1, maxRows: 2 }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} md={12} xl={12}>
                                    <Form.Item
                                        name="numberOfFloor"
                                        label="อยู่ชั้นที่ (ถ้าไม่มีให้ใส่ 0)"
                                        rules={[{ required: true, message: 'กรุณากรอกจำนวนชั้น' }]}
                                    >
                                        <InputNumber
                                            min={0} max={200}
                                            style={{ width: "100%" }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} md={12} xl={12}>
                                    <Form.Item
                                        name="numberOfBedroom"
                                        label="จำนวนห้องนอน (ถ้าไม่มีให้ใส่ 0)"
                                        rules={[{ required: true, message: 'กรุณากรอกจำนวนห้องนอน' }]}
                                    >
                                        <InputNumber
                                            min={0} max={200}
                                            style={{ width: "100%" }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} md={12} xl={12}>
                                    <Form.Item
                                        name="numberOfBathroom"
                                        label="จำนวนห้องน้ำ (ถ้าไม่มีให้ใส่ 0)"
                                        rules={[{ required: true, message: 'กรุณากรอกจำนวนห้องน้ำ' }]}
                                    >
                                        <InputNumber
                                            min={0} max={200}
                                            style={{ width: "100%" }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} md={12} xl={12}>
                                    <Form.Item
                                        name="numberOfUsableArea"
                                        label="จำนวนพื้นที่ (ตร.ม.)"
                                        rules={[{ required: true, message: 'กรุณากรอกจำนวนพื้นที่ (ตร.ม.)' }]}
                                    >
                                        <InputNumber
                                            min={0} max={20000}
                                            style={{ width: "100%" }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} md={12} xl={12}>
                                    <Form.Item
                                        name="numberOfParkCar"
                                        label="จำนวนที่จอดรถ (ถ้าไม่มีให้ใส่ 0)"
                                        rules={[{ required: true, message: 'กรุณากรอกจำนวนที่จอดรถ' }]}
                                    >
                                        <InputNumber
                                            min={0} max={200}
                                            style={{ width: "100%" }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} md={12} xl={12}>
                                    <div>
                                        <Form.Item
                                            name="propertyTypeId"
                                            label="ประเภทอสังหาฯ"
                                            rules={[{ required: true, message: 'กรุณาเลือกประเภทอสังหาฯ' }]}
                                        >
                                            <Select
                                                showSearch
                                                style={{ width: '100%' }}
                                                optionFilterProp="children"
                                                allowClear
                                            >
                                                {propertyTypes?.map((val, index) => {
                                                    return (
                                                        <Option key={index} value={val.id}>{val.name}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </Col>

                                <Col xs={24} md={12} xl={12}>
                                    <Form.Item
                                        name="isRent"
                                        label="ให้เเช่า"
                                        rules={[{ required: true, message: 'กรุณากรอกจำนวนที่จอดรถ' }]}
                                    >
                                        <Radio.Group>
                                            <Radio value={true}>ใช่</Radio>
                                            <Radio value={false}>ไม่ใช่</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>

                                <Col xs={24} md={12} xl={12}>
                                    <Form.Item
                                        name="isSale"
                                        label="ให้ขาย"
                                        rules={[{ required: true, message: 'กรุณากรอกจำนวนที่จอดรถ' }]}
                                    >
                                        <Radio.Group>
                                            <Radio value={true}>ใช่</Radio>
                                            <Radio value={false}>ไม่ใช่</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        name="description_detail"
                                        label="รายละเอียดอสังหาฯ"
                                    >
                                        <CKEditor
                                            editor={ClassicEditor}
                                            config={{
                                                toolbar: {
                                                    items: [
                                                        'heading', '|',
                                                        'fontfamily', 'fontsize', '|',
                                                        'bold', 'italic', 'underline', '|',
                                                        'alignment', '|',
                                                        'fontColor', 'fontBackgroundColor', '|',
                                                        'bulletedList', 'numberedList', 'todoList', '|',
                                                        'code', 'codeBlock', '|',
                                                        'undo', 'redo'
                                                    ],
                                                    removeButtons: 'Subscript,Superscript',
                                                    height: 350
                                                }
                                            }}
                                            data={detail}
                                            onBlur={(event, editor) => {
                                                const data = editor.getData()
                                                detailRef.current = data
                                            }}
                                        />
                                    </Form.Item>
                                </Col>

                                {/* <Col span={24}>
                                <Form.Item
                                    name="promotion"
                                    label="โปรโมชั่น"
                                >
                                    <CKEditor
                                        editor={ClassicEditor}
                                        config={{
                                            toolbar: {
                                                items: [
                                                    'heading', '|',
                                                    'fontfamily', 'fontsize', '|',
                                                    'bold', 'italic', 'underline', '|',
                                                    'alignment', '|',
                                                    'fontColor', 'fontBackgroundColor', '|',
                                                    'bulletedList', 'numberedList', 'todoList', '|',
                                                    'code', 'codeBlock', '|',
                                                    'undo', 'redo'
                                                ],
                                                removeButtons: 'Subscript,Superscript',
                                                height: 350
                                            }
                                        }}
                                        data={promotion}
                                        onBlur={(event, editor) => {
                                            const data = editor.getData()
                                            promotionRef.current = data
                                        }}
                                    />
                                </Form.Item>
                            </Col> */}
                            </Row>
                        </Col>

                        <Col xs={24} md={12} xl={12}>
                            <Row gutter={[24, 0]}>
                                <Col xs={24} md={12} xl={12}>
                                    <div style={{ display: "grid" }}>
                                        <label style={{ paddingBottom: 6 }}>ภาพปกอสังหาฯ</label>
                                        {imagePropertyURL?.imageUrl ?
                                            <img
                                                style={{ borderRadius: 8, maxWidth: "100%", border: "1px solid #EEEEEE" }}
                                                src={`${imagePropertyURL.imageUrl}`}
                                            />
                                            :
                                            <img
                                                style={{ width: "100%", borderRadius: 8, border: "1px solid #C4C4C4" }}
                                                src={`./assets/images/default/df-img.png`}
                                            />
                                        }
                                        <div style={{ paddingTop: 24, paddingBottom: 24 }}>
                                            <Upload
                                                {...optionPropertyImage}
                                                accept='image/jpeg, image/png, image/jfif'
                                                style={{ width: "100%" }}
                                                maxCount={1}
                                                showUploadList={false}
                                            >
                                                <Button
                                                    type="default"
                                                    style={{ width: "100%" }}
                                                    icon={imagePropertyURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                >อัพโหลดรูปภาพ</Button>
                                            </Upload>
                                        </div>
                                    </div>
                                </Col>

                                <Col xs={24} md={12} xl={12}>
                                    <Form.Item
                                        name="price"
                                        label="ราคาอสังหาฯ"
                                        rules={[{ required: true, message: 'กรุณาเลือกราคาอสังหาฯ' }]}
                                    >
                                        <InputNumber
                                            min={0}
                                            style={{ width: "100%" }}
                                        />
                                    </Form.Item>
                                </Col>

                                {modalProperty.title !== "add" ?
                                    <Col span={24} style={{ paddingTop: 24 }}>
                                        <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
                                            <label>รายการรูปผลงาน</label>

                                            {/* <Button
                                            type="primary"
                                            style={{ float: 'right' }}
                                            onClick={() => {
                                                isActivePropertyGalleryRef.current = true
                                                setModalPropertyGallery({
                                                    isShow: true,
                                                    title: "เพิ่มภาพอสังหาฯ"
                                                })
                                            }}
                                        >เพิ่มรูปผลงาน</Button> */}

                                            <Upload
                                                {...optionPropertyGalleryImage}
                                                accept='image/jpeg, image/png, image/jfif'
                                                style={{ width: "100%" }}
                                                maxCount={1}
                                                showUploadList={false}
                                            >
                                                <Button
                                                    type="primary"
                                                    style={{ width: "100%" }}
                                                    icon={imagePropertyGalleryURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                >เพิ่มรูปผลงาน</Button>
                                            </Upload>

                                        </div>
                                        <div style={{ paddingTop: 12, paddingBottom: 24 }}>
                                            <div style={{ padding: 12, border: "2px solid #EEEEEE", borderRadius: 16 }}>
                                                <Row gutter={[12, 24]}>
                                                    {!loadingPropertyGallery ?
                                                        <>
                                                            {propertyGallery?.map((val, index) => {
                                                                return (
                                                                    <Col xs={24} md={12} xl={6}>
                                                                        <img
                                                                            // src={`${serverUrl}/${val.image}`}
                                                                            src={`${val.image}`}
                                                                            style={{ objectFit: "cover", width: "100%", height: 200, borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
                                                                        />
                                                                        <Button
                                                                            type="primary"
                                                                            style={{ width: '100%' }}
                                                                            onClick={async () => {
                                                                                await handlePropertiesGalleryDelete(val.id)
                                                                            }}
                                                                        >ลบรูปภาพ</Button>
                                                                    </Col>
                                                                )
                                                            })}
                                                        </>
                                                        : []
                                                    }

                                                    {propertyGallery?.length === 0 ?
                                                        <Col span={24}>
                                                            <div style={{ padding: 24, display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", backgroundColor: "#EEEEEE" }} >
                                                                <label>ไม่มีรูปภาพ</label>
                                                            </div>
                                                        </Col>
                                                        : []
                                                    }
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>
                                    : []
                                }

                                <Col xs={24} md={12} xl={12}>
                                    <Form.Item
                                        name="isActive"
                                        label="เเสดงอสังหาฯหรือไม่ ?"
                                        rules={[{ required: true, message: 'กรุณาเลือกสถานะการเเสดง' }]}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            optionFilterProp="children"
                                            allowClear
                                        >
                                            <Option key={0} value={true}>เปิด</Option>
                                            <Option key={1} value={false}>ปิด</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                {/* <Col xs={24} md={12} xl={12}>
                                    <div style={{ display: "grid" }}>
                                        <label style={{ paddingBottom: 6 }}>วิดีโอสินค้า (ไม่บังคับ)</label>
                                        {videoProductDetailURL?.videoUrl ?
                                            <div style={{ backgroundColor: "black", borderRadius: 8 }}>
                                                <Video
                                                    url={videoProductDetailURL.videoUrl}
                                                    title={""}
                                                    height={"100%"}
                                                    width={"100%"}
                                                />
                                            </div>
                                            :
                                            <img
                                                style={{ width: "100%", borderRadius: 8, border: "1px solid #C4C4C4" }}
                                                src={`./assets/images/default/df-vdo.png`}
                                            />
                                        }
                                        <div style={{ paddingTop: 12 }}>
                                            <Upload
                                                {...optionProductDetailVideo}
                                                accept='.mp4'
                                                style={{ width: "100%" }}
                                                maxCount={1}
                                                showUploadList={{ showRemoveIcon: false }}
                                            >
                                                <Button
                                                    type="default"
                                                    style={{ width: "100%" }}
                                                    icon={videoProductDetailURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                >อัพโหลดวิดีโอ</Button>
                                            </Upload>
                                        </div>
                                    </div>
                                </Col> */}
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            {/* <Modal
                title={<strong><label className="topic-color-bold">{modalPropertyGallery.title}</label></strong>}
                visible={modalPropertyGallery.isShow}
                zIndex={1000}
                onCancel={() => {
                    // default
                    setFormPropertyGalleryDefault()
                }}
                width={500}
                onOk={() => {
                    // formPropertyGallery.submit()
                }}
                // okText={<label style={{ width: 50, cursor: 'pointer' }}>บันทึก</label>}
                // cancelText={<label style={{ width: 50, cursor: 'pointer' }}>ยกเลิก</label>}
                footer={[]}
            >
                <Row>
                    <Col span={24}>
                        <div style={{ display: "grid" }}>
                            <label style={{ paddingBottom: 6 }}>ภาพปกอสังหาฯ</label>
                            {imagePropertyGalleryURL?.imageUrl ?
                                <img
                                    style={{ borderRadius: 8, maxWidth: 300, border: "1px solid #EEEEEE" }}
                                    src={`${imagePropertyGalleryURL.imageUrl}`}
                                />
                                :
                                <img
                                    style={{ width: "100%", borderRadius: 8, border: "1px solid #C4C4C4" }}
                                    src={`./assets/images/default/df-img.png`}
                                />
                            }
                            <div style={{ paddingTop: 12 }}>
                                <Upload
                                    {...optionPropertyGalleryImage}
                                    accept='image/jpeg, image/png, image/jfif'
                                    style={{ width: "100%" }}
                                    maxCount={1}
                                    showUploadList={{ showRemoveIcon: false }}
                                >
                                    <Button
                                        type="default"
                                        style={{ width: "100%" }}
                                        icon={imagePropertyGalleryURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                    >อัพโหลดรูปภาพ</Button>
                                </Upload>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Modal> */}
        </Row>
    )
}
