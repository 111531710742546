/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import {
    Row,
    Col,
    Button
} from 'antd'
import { Icon } from '@iconify/react'
import { useDimensions } from '../../../../common/mamager/ScreenManager'
import { scroller } from "react-scroll"

export default function Main() {

    const { width } = useDimensions()

    return (
        <>
            <div
                style={{
                    backgroundImage: width > 1366 ? "url(./assets/images/background/building.jpg)" : ( width <= 1366 && width > 1065 ? "url(./assets/images/background/building-md.jpg)" : ( width <= 1065 && width > 767 ? "url(./assets/images/background/building-sm.jpg)" : "url(./assets/images/background/building-vsm.jpg)")),
                    backgroundSize: (width > 760 ? '100%' : '255%'),
                    width: '100% auto',
                    height: '100% auto',
                    filter: "brightness(110%)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <div
                    style={{
                        // display: "flex",
                        // justifyContent: width > 760 ? "space-between" : "center",
                        paddingTop: width > 1200 ? 150 : (width > 760 && width < 1200 ? 150 : 150),
                        paddingBottom: 82,
                        paddingLeft: (width > 1200 ? 0 : 24),
                        paddingRight: (width > 1200 ? 0 : 24),
                        maxWidth: 1200,
                        width: "100%"
                    }}
                >
                    <div
                        style={{
                            backgroundColor: 'rgba(157, 0, 255, 0.35)',
                            borderRadius: 16,
                            paddingLeft: 24,
                            paddingRight: 24,
                            paddingTop: 24,
                            paddingBottom: 24
                        }}
                    >
                        <div style={{ display: width > 645 ? "flex" : "grid", alignItems: "flex-start", justifyContent: "flex-start" }}>
                            <img src={"./assets/images/logo/logo-is-asset-home.png"} style={{ width: (width > 645 ? 200 : "100%"), height: (width > 645 ? 200 : "100%"), borderRadius: 16 }} />

                            <div style={{ paddingLeft: (width > 645 ? 24 : 0), paddingTop: (width > 645 ? 0 : 24), display: "grid" }}>
                                <label style={{ fontSize: 26 }}>
                                    Is Asset Home
                                </label>
                                <label style={{ fontSize: 23 }}>
                                    เว็บไซต์ ขายบ้านเช่าบ้าน.com
                                </label>
                                <label style={{ fontSize: 23 }}>
                                    รวมอสังหาทุกประเภทไว้ให้ที่นี่ ทั้งกรุงเทพ ปริณฑล
                                </label>
                                <label style={{ fontSize: 23 }}>
                                    รับฝาก-ขาย และหาเช่าบ้าน   ทางเราพร้อมให้คำแนะนำปรึกษาได้ฟรีตลอด
                                </label>
                                <label style={{ fontSize: 23 }}>
                                    Line : @377wudef
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>

    )
}