import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getHomePageBlogsFetch = async (param, body, accessToken) => {
    try {
        const result = await httpClient.get(server.GET_HOMEPAGE_BLOG_URL + 
            `?page=${param.page}&size=${param.size}`
        )
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getBlogDetailPageFetch = async (param, body, accessToken) => {
    try {
        // console.log("X --- : ", server.GET_BLOG_DETAIL_PAGE_URL + `/${param.id}`)
        const result = await httpClient.get(server.GET_BLOG_DETAIL_PAGE_URL + `/${param.id}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getHomePageBlogsFetch,
    getBlogDetailPageFetch,

    // insert

    // update

    // delete
}