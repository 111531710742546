/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'
import Main from './components/main'
import Contact from './components/contact'
import RealEstate from '../realEstate'
import {
    Row,
    Col
} from 'antd'
import { Element, scroller } from 'react-scroll'
import { useDimensions } from '../../common/mamager/ScreenManager'
import Header from '../../common/components/header'
import { useLocation } from 'react-router'
import Blog from '../blog'
import Footer from '../../common/components/footer'
import Gallery from '../../common/components/gallery'

export default function Home() {

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const menu = params.get("menu")

    const { width } = useDimensions()

    const [previousWork, setPreviousWork] = useState([])

    const [loading, setLoading] = useState(false)

    const genScroll = () => {
        if (menu === "home") {
            scroller.scrollTo("home", {
                duration: 1500,
                delay: 100,
                smooth: true,
                offset: -78,
            })
        } else if (menu === "our-services") {
            scroller.scrollTo("our-services", {
                duration: 1500,
                delay: 100,
                smooth: true,
                offset: -78,
            })
        } else if (menu === "product") {
            scroller.scrollTo("product", {
                duration: 1500,
                delay: 100,
                smooth: true,
                offset: -78,
            })
        } else if (menu === "about") {
            scroller.scrollTo("about", {
                duration: 1500,
                delay: 100,
                smooth: true,
                offset: -78,
            })
        } else if (menu === "contact") {
            scroller.scrollTo("contact", {
                duration: 1500,
                delay: 100,
                smooth: true,
                offset: -78,
            })
        }
    }

    const getPreviousWorkAll = async () => {
        let result = [
            {
                image: "./assets/images/past_work/pw1.jpg"
            },
            {
                image: "./assets/images/past_work/pw2.jpg"
            },
            {
                image: "./assets/images/past_work/pw3.jpg"
            }
        ] // await getPreviousWorkAllFetch()
        // console.log("getPreviousWorkAllFetch : ", result)
        if (result) {
            setPreviousWork(result)
        }
    }

    const getBaseApi = async () => {
        setLoading(true)

        await getPreviousWorkAll()

        setLoading(false)
    }

    useEffect(() => {
        getBaseApi()
        genScroll()
    }, [])

    return (
        <>
            <Header />

            <div style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Row>
                    <Col span={24}>
                        <Element name="home">
                            <Main />
                        </Element>
                    </Col>
                    <Col span={24}>
                        <Element name="single_house">
                            <RealEstate background={"#FFFFFF"} typeId={1} typeName="บ้านเดี่ยว" />
                        </Element>
                    </Col>
                    <Col span={24}>
                        <Element name="condo">
                            <RealEstate background={"#EEEEEE"} typeId={2} typeName="ทาวน์เฮ้าว์ / ทาวน์โฮม" />
                        </Element>
                    </Col>
                    <Col span={24}>
                        <Element name="office_and_shop">
                            <RealEstate background={"#FFFFFF"} typeId={3} typeName="คอนโด" />
                        </Element>
                    </Col>
                    <Col span={24}>
                        <Element name="townhouse_and_townhome">
                            <RealEstate background={"#EEEEEE"} typeId={4} typeName="ออฟฟิศ / ร้านค้า" />
                        </Element>
                    </Col>
                    <Col span={24}>
                        <Element name="warehouse_and_factory">
                            <RealEstate background={"#FFFFFF"} typeId={5} typeName="โกดัง / โรงงาน" />
                        </Element>
                    </Col>
                    <Col span={24}>
                        <Element name="land">
                            <RealEstate background={"#EEEEEE"} typeId={6} typeName="ที่ดิน" />
                        </Element>
                    </Col>
                    <Col span={24}>
                        <Element name="blog">
                            <Blog />
                        </Element>
                    </Col>
                    <Col span={24}>
                        <Gallery
                            typeId={1}
                            loading={loading}
                            data={previousWork}
                            typeName={"ผลงานที่ผ่านมา"}
                            isOpenPaddingLeft={true}
                            isOpenPaddingRight={true}
                            backgroundColor={"#EEEEEE"}
                            isOpenBaseApi={false}
                        />
                    </Col>
                </Row>
            </div>

            <Footer />
        </>
    )
}
